import React from "react";

function ServersSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
<svg className={props.className} width="143" height="143" viewBox="0 0 143 143" xmlns="http://www.w3.org/2000/svg" fill="none">
<path d="M136.104 52.6306H6.89615C3.19237 52.6306 0.179199 55.6439 0.179199 59.3476V84.3987C0.179199 88.102 3.19237 91.1149 6.89615 91.1149H136.104C139.808 91.1149 142.821 88.102 142.821 84.3987V59.3476C142.821 55.6439 139.808 52.6306 136.104 52.6306ZM137.816 84.3987C137.816 85.3427 137.048 86.11 136.104 86.11H6.89615C5.95211 86.11 5.18412 85.3427 5.18412 84.3987V59.3476C5.18412 58.4036 5.95211 57.6356 6.89615 57.6356H136.104C137.048 57.6356 137.816 58.4036 137.816 59.3476L137.816 84.3987Z" fill="white"/>
<path d="M20.8696 67.6262C18.5246 67.6262 16.6233 69.5276 16.6233 71.8724C16.6233 74.2176 18.5246 76.1183 20.8696 76.1183C23.2146 76.1183 25.1159 74.2176 25.1159 71.8724C25.116 69.5276 23.2146 67.6262 20.8696 67.6262Z" fill="white"/>
<path d="M35.5131 67.6262C33.1677 67.6262 31.2664 69.5276 31.2664 71.8724C31.2664 74.2176 33.1676 76.1183 35.5131 76.1183C37.8577 76.1183 39.759 74.2176 39.759 71.8724C39.759 69.5276 37.8576 67.6262 35.5131 67.6262Z" fill="white"/>
<path d="M50.1565 67.6262C47.8112 67.6262 45.9099 69.5276 45.9099 71.8724C45.9099 74.2176 47.8112 76.1183 50.1565 76.1183C52.5012 76.1183 54.4024 74.2176 54.4024 71.8724C54.4024 69.5276 52.5012 67.6262 50.1565 67.6262Z" fill="white"/>
<path d="M124.548 69.3694H73.6653C72.2835 69.3694 71.1628 70.4897 71.1628 71.8716C71.1628 73.2541 72.2835 74.3741 73.6653 74.3741H124.548C125.93 74.3741 127.051 73.2541 127.051 71.8716C127.051 70.4897 125.93 69.3694 124.548 69.3694Z" fill="white"/>
<path d="M136.104 104.515H6.89615C3.19237 104.515 0.179199 107.529 0.179199 111.232V136.284C0.179199 139.987 3.19237 143 6.89615 143H136.104C139.808 143 142.821 139.987 142.821 136.284V111.232C142.821 107.529 139.808 104.515 136.104 104.515ZM137.816 136.284C137.816 137.228 137.048 137.995 136.104 137.995H6.89615C5.95211 137.995 5.18412 137.228 5.18412 136.284V111.232C5.18412 110.288 5.95211 109.52 6.89615 109.52H136.104C137.048 109.52 137.816 110.288 137.816 111.232L137.816 136.284Z" fill="white"/>
<path d="M20.8696 119.511C18.5246 119.511 16.6233 121.413 16.6233 123.757C16.6233 126.103 18.5246 128.003 20.8696 128.003C23.2146 128.003 25.1159 126.103 25.1159 123.757C25.116 121.413 23.2146 119.511 20.8696 119.511Z" fill="white"/>
<path d="M35.5131 119.511C33.1677 119.511 31.2664 121.413 31.2664 123.757C31.2664 126.103 33.1676 128.003 35.5131 128.003C37.8577 128.003 39.759 126.103 39.759 123.757C39.759 121.413 37.8576 119.511 35.5131 119.511Z" fill="white"/>
<path d="M50.1565 119.511C47.8112 119.511 45.9099 121.413 45.9099 123.757C45.9099 126.103 47.8112 128.003 50.1565 128.003C52.5012 128.003 54.4024 126.103 54.4024 123.757C54.4024 121.413 52.5012 119.511 50.1565 119.511Z" fill="white"/>
<path d="M124.548 121.254H73.6653C72.2835 121.254 71.1628 122.375 71.1628 123.757C71.1628 125.139 72.2835 126.259 73.6653 126.259H124.548C125.93 126.259 127.051 125.139 127.051 123.757C127.051 122.375 125.93 121.254 124.548 121.254Z" fill="white"/>
<path d="M136.104 0H6.89623C3.19245 0 0.179199 3.01325 0.179199 6.71703V31.7682C0.179199 35.4716 3.19237 38.4845 6.89615 38.4845H136.104C139.808 38.4845 142.821 35.4716 142.821 31.7682V6.71703C142.821 3.01325 139.808 0 136.104 0ZM137.816 31.7682C137.816 32.7118 137.048 33.4796 136.104 33.4796H6.89623C5.95219 33.4796 5.1842 32.7118 5.1842 31.7682V6.71703C5.1842 5.77299 5.95219 5.00492 6.89623 5.00492H136.104C137.048 5.00492 137.816 5.77299 137.816 6.71703L137.816 31.7682Z" fill="white"/>
<path d="M20.8694 23.4879C23.2145 23.4879 25.1156 21.5868 25.1156 19.2418C25.1156 16.8967 23.2145 14.9956 20.8694 14.9956C18.5244 14.9956 16.6233 16.8967 16.6233 19.2418C16.6233 21.5868 18.5244 23.4879 20.8694 23.4879Z" fill="white"/>
<path d="M35.5131 14.9956C33.1677 14.9956 31.2664 16.8969 31.2664 19.2419C31.2664 21.587 33.1676 23.4879 35.5131 23.4879C37.8577 23.4879 39.759 21.587 39.759 19.2419C39.759 16.8969 37.8576 14.9956 35.5131 14.9956Z" fill="white"/>
<path d="M50.1565 14.9956C47.8112 14.9956 45.9099 16.8969 45.9099 19.2419C45.9099 21.587 47.8112 23.4879 50.1565 23.4879C52.5012 23.4879 54.4024 21.587 54.4024 19.2419C54.4024 16.8969 52.5012 14.9956 50.1565 14.9956Z" fill="white"/>
<path d="M124.548 16.7388H73.6653C72.2835 16.7388 71.1628 17.8591 71.1628 19.2413C71.1628 20.6234 72.2835 21.7438 73.6653 21.7438H124.548C125.93 21.7438 127.051 20.6234 127.051 19.2413C127.051 17.8591 125.93 16.7388 124.548 16.7388Z" fill="white"/>
</svg>
    )
}
export {ServersSvgComponent};
