import React from 'react';
import styles from './SelectButton.module.scss';
function SelectButton(props: {isActive: boolean, children: string, onClick: React.MouseEventHandler<HTMLButtonElement>, onMouseOver: React.MouseEventHandler<HTMLButtonElement>, onMouseOut: React.MouseEventHandler<HTMLButtonElement>}) {
    const processedWords = (() => {
      if (props.children.includes('Й')) {
        const parts = props.children.split('Й');
        return (
          <>
            {parts[0]}
            <span style={{ fontSize: '1.27em' }}>й</span>
            {parts[1]}
          </>
        );
      } else {
        return props.children;
      }
    });
    return (
        <button onMouseOut={(event: React.MouseEvent<HTMLButtonElement>) => props.onMouseOut && props.onMouseOut(event)} onMouseOver={(event: React.MouseEvent<HTMLButtonElement>) => props.onMouseOver && props.onMouseOver(event)} onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.onClick(event)} className={styles.button}>{processedWords()}</button>
    )
} 

export {SelectButton}