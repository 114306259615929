import { Header } from "../Header";
import { Footer } from "../Footer";
import { useState, useEffect, useRef } from "react";
import { NavigateFunction, useLocation, useNavigate  } from "react-router-dom";
import React from "react";
import { useCookies } from "react-cookie";
import styles from './WelcomeApp.module.scss';
import { AddToServerTextSvgComponent } from "../assets/addToServerText";
import { DiscordSvgComponent } from "../assets/discord";
import { ChooseServerTextSvgComponent } from "../assets/chooseServerText";
import { ServersSvgComponent } from "../assets/servers";
import futurePhoto from '../assets/futer_photo.png'
function WelcomeApp():React.JSX.Element {
  const [cookies] = useCookies(['user_token'])
  const [, setData] = useState<string>('Loading...');
  const [auth, setAuth] = useState<boolean>(false);
  const refCommands = useRef<null | HTMLDivElement>(null);
  const refMusic = useRef<null | HTMLDivElement>(null);
  const refEconomic = useRef<null | HTMLDivElement>(null);
  const refRating = useRef<null | HTMLDivElement>(null);
  const refAugit = useRef<null | HTMLDivElement>(null);
  const refMain = useRef<null | HTMLDivElement>(null);
  // const [loading, setLoading] = useState(false);
  const navigate: NavigateFunction = useNavigate();
  const getInvLink = () => {
    window.open(`https://discord.com/oauth2/authorize?client_id=1268113414530797588&permissions=8&integration_type=0&scope=bot+applications.commands`,
    "_blank", 'location=yes,height=850,width=680,scrollbars=yes,status=yes')
  }
  const location = useLocation();
  useEffect(() => {
      if (location.state && location.state.scroll === false) {
          window.scrollTo(0, 0);
      }
  }, [location]);
  useEffect(() => {
    (async() => {
      // await import("@codemirror/lang-python")
      if (cookies.user_token) {
        setAuth(true)
      }
      const data = await (await fetch('https://omnibot.su/api/ping')).json();
      if(data.express) setData("Бэкенд подключен")
    })()
  }, [cookies.user_token]);

  useEffect(() => {
    const hashObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            (entry.target as HTMLDivElement).style.transform = 'translateX(0) translateY(0)';
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: .05 }
    )
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {

            (entry.target as HTMLDivElement).style.opacity = '1';
            (entry.target as HTMLDivElement).style.transform = 'translateY(0)';
            const hashtag = (entry.target.children[1].children[2] || entry.target.children[0].children[2]) as HTMLDivElement;
            hashObserver.observe(hashtag)
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: .4 }
    );
    if (refCommands.current) {
      observer.observe(refCommands.current);
    }
    if (refMusic.current) {
      observer.observe(refMusic.current);
    }
    if (refEconomic.current) {
      observer.observe(refEconomic.current);
    }
    if (refRating.current) {
      observer.observe(refRating.current);
    }
    if (refAugit.current) {
      observer.observe(refAugit.current);
    }

    return () => observer.disconnect(); 
  }, [])
  useEffect(() => {
    if (!refMusic.current || !refRating.current) return;
    const imgMusic = refMusic.current.children[1] as HTMLImageElement;
    const textMusic = refMusic.current.children[0] as HTMLDivElement;
    const imgRating = refRating.current.children[1] as HTMLImageElement;
    const textRating = refRating.current.children[0] as HTMLDivElement;

    const handleResize = () => {
      if (!refMusic.current || !refRating.current) return;
      if (window.innerWidth < 1700) {
        textMusic.insertAdjacentElement('beforebegin', imgMusic);
        textRating.insertAdjacentElement('beforebegin', imgRating);
      } else {
        refMusic.current.insertBefore(textMusic, imgMusic);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Инициализация

    return () => window.removeEventListener('resize', handleResize);
  }, [])
  useEffect(() => {
    if (!refMain.current) return;
    const mainRef = refMain.current;
    mainRef.addEventListener('contextmenu', event => event.preventDefault());
    mainRef.addEventListener('dragstart', event => event.preventDefault());
    return () => {
      if (!mainRef) return;
      mainRef.removeEventListener('contextmenu', () => {});
      mainRef.removeEventListener('dragstart', () => {});
    }
  }, [])
  return (
    <>
    <Header/>
      <main ref={refMain} className={styles.main}>
        <div className={styles.firstPart}>
          <div className={styles.mainSection}>
              <div className={styles.text}>
                  <span className={styles.omni}>
                    OMNI
                    <br />
                    <span className={styles.disbot}>DISCORD BOT</span>
                  </span>
                  <br />
              </div>
              <div className={styles.circls}>
                <div onClick={getInvLink} className={styles.invToServer}>
                  <AddToServerTextSvgComponent className={styles.addToServerText}/>
                  <DiscordSvgComponent className={styles.discordSvg}/>
                </div>
                {auth && (
                  <div onClick={() => navigate("/guilds", { state: { scroll: false } })} className={styles.servers}>
                    <ChooseServerTextSvgComponent className={styles.chooseServerText} />
                    <ServersSvgComponent className={styles.serversSvg}/>
                  </div>
                )}
              </div>
          </div>
        </div>
        <div className={styles.secondPart}>
          <div ref={refCommands} className={styles.commands}>
            <img className={styles.futurePhoto} src={futurePhoto} alt="" />
            <div className={styles.text}>
              <span className={styles.name}>КОМАНДЫ</span>
              <span className={styles.value}>Вы можете создавать и редактировать свои собственные команды, благодаря нашему скриптовому языку программирования, удобной документации, и библиотеки discord.js
              </span>
              <span className={styles.hashtag}>#OMNI</span>
            </div>
          </div>
          <div ref={refMusic} className={styles.music}>
            <div className={styles.text}>
              <span className={styles.name}>МУЗЫКА</span>
              <span className={styles.value}>С помощью OMNI вы сможете слушать любимые мелодии и даже плейлисты не выходя из голосового канала
              </span>
              <span className={styles.hashtag}>#OMNI</span>
            </div>
            <img className={styles.futurePhoto} src={futurePhoto} alt="" />
          </div>
          <div ref={refEconomic} className={styles.ecomonic}>
            <img className={styles.futurePhoto} src={futurePhoto} alt="" />
            <div className={styles.text}>
              <span className={styles.name}>ЭКОНОМИКА</span>
              <span className={styles.value}>Система легкая и понятная для всех пользователей, добавляет внутри серверный магазин, где можно что-то купить за монеты сервера
              </span>
              <span className={styles.hashtag}>#OMNI</span>
            </div>
          </div>
          <div ref={refRating} className={styles.rating}>
            <div className={styles.text}>
              <span className={styles.name}>РЕ<span style={{fontSize: '1.27em'}}>й</span>ТИНГ</span>
              <span className={styles.value}>У нашего бота есть отличная система уровней за активность пользователей, которую вы можете сами настроить. Её можно объединить с другими серверами
              </span>
              <span className={styles.hashtag}>#OMNI</span>
            </div>
            <img className={styles.futurePhoto} src={futurePhoto} alt="" />
          </div>
          <div ref={refAugit} className={styles.audit}>
            <img className={styles.futurePhoto} src={futurePhoto} alt="" />
            <div className={styles.text}>
              <span className={styles.name}>АУДИТ</span>
              <span className={styles.value}>В OMNI присутствует удобная система логирования действий пользователей Discord 
              </span>
              <span className={styles.hashtag}>#OMNI</span>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
        
    </>
  )
}

export { WelcomeApp }