import React from "react";

function MarkSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
<svg className={props.className} width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 2L7.84615 16L2 9.77778" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>





    )
}

export {MarkSvgComponent};