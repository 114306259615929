import React, { useLayoutEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";

const CommandApp = (): React.JSX.Element => {
    const { guildId, commandId } = useParams();
    const nowLocation = useLocation();
    const [cookies] = useCookies(['user_token']);
    useLayoutEffect(() => {
        (async() => {
            if (nowLocation.state && nowLocation.state.newCommand) { /* empty */ } else {
                // const commandData = await fetch(`https://omnibot.su/api/getCommand?guildId=${guildId}&commandId=${commandId}`, {
                //     headers: {
                //       Authorization: cookies.user_token
                //     }
                //   });
                // if (commandData.status !== 202) {
                //     location.replace('/')
                // }
                // const commandDataJson = await commandData.json();
            }
            if (nowLocation.state && nowLocation.state.scroll === false) {
                window.scrollTo(0, 0);
            }
        })()
    }, [commandId, cookies.user_token, guildId, nowLocation]);
    return (
        <div></div>
    )
}

export {CommandApp}