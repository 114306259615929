import React from "react";

function StatsSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 20H0V17.7778H17V20ZM4.72222 7.77778H0.944444V15.5556H4.72222V7.77778ZM10.3889 0H6.61111V15.5556H10.3889V0ZM16.0556 3.33333H12.2778V15.5556H16.0556V3.33333Z" fill="white"/>
        </svg>
    )
}

export {StatsSvgComponent};