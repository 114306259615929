import React, { memo } from "react";
import styles from './Footer.module.scss';
import { DiscordSvgComponent } from "../assets/discord";
import { BoostySvgComponent } from "../assets/boosty";
import { useNavigate } from "react-router-dom";
const Footer = memo((): React.JSX.Element => {
    const navigate = useNavigate();
    return (
        <footer className={styles.footer}>
            <div className={styles.line}>
                <div className={styles.circleWrap}></div>
            </div>
            <div className={styles.main}>
                <div className={styles.mainPart}>
                    <div className={styles.omni} onClick={() => navigate('/', { state: { scroll: false } })}>OMNI</div>
                    <div className={styles.icons}>
                        <DiscordSvgComponent className={styles.discordSvg}/>
                        <BoostySvgComponent className={styles.boostySvg}/>
                    </div>
                </div>
                <div className={styles.links}>
                    <button className={styles.button} onClick={() => navigate('/terms-of-use', { state: { scroll: false } })}>Условия пользования</button>
                    <button className={styles.button} onClick={() => navigate('/confidentiality', { state: { scroll: false } })}>Политика конфиденциальности</button>
                    <button className={styles.button} onClick={() => navigate('/cookie', { state: { scroll: false } })}>Файлы Cookie</button>
                </div>
                <div className={styles.copyright}>© 2024 • Все права защищены</div>
            </div>
            
        </footer>
    );
})

export {Footer}