import React from "react";

function LoadingSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M392.069 658.547C346.184 662.28 300.116 654.068 258.346 634.718C216.576 615.365 180.528 585.529 153.706 548.114C126.887 510.696 110.211 466.974 105.3 421.201C100.392 375.429 107.414 329.165 125.685 286.911" stroke="white" strokeWidth="68" strokeLinecap="round"/>
        </svg>





    )
}

export {LoadingSvgComponent};