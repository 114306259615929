import { useEffect, useRef, useState, useTransition } from 'react';
import styles from './Common.module.scss';
import { CheckMarkFullSvgComponent } from '../../../assets/checkMarkFull';
import { CheckMarkSvgComponent } from '../../../assets/checkMark';
import { useDebounce } from 'use-debounce';
import { CommonData, GuildRoles } from '../../../types';
import { useCookies } from 'react-cookie';
import { MarkSvgComponent } from '../../../assets/mark';
import { PlusSvgComponent } from '../../../assets/plus';
import { CrossSvgComponent } from '../../../assets/cross';
import chroma from 'chroma-js';
function Common(props: {data: CommonData}): React.JSX.Element {
    const [prefix, setPrefix] = useState(props.data.common.prefix);
    const refCount = useRef<null | HTMLDivElement>(null);
    const refSaved = useRef<null | HTMLDivElement>(null);
    const refSavedAllowedRoles = useRef<null | HTMLDivElement>(null);
    const refSavedDefaultRoles = useRef<null | HTMLDivElement>(null);
    const [, startTransition] = useTransition();
    const [cookies] = useCookies(['user_token']);
    const [canUseCommands, setCanUseCommands] = useState(props.data.common.canUseCommands);
    const [canUseSlashCommands, setCanUseSlashCommands] = useState(props.data.common.canUseSlashCommands);
    const [allowedRoles, setAllowedRoles] = useState(props.data.common.allowedRoles);
    const [defaultRoles, setDefaultRoles] = useState(props.data.common.defaultRoles);
    const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
    const [isDefaultRoleMenuOpen, setIsDefaultRoleMenuOpen] = useState(false);
    const [serverRoles, setServerRoles] = useState<[] | GuildRoles[]>([])
    const [serverDefaultRoles, setServerDefaultRoles] = useState<[] | GuildRoles[]>([])
    const [getAutomaticallyDefaultRoles, setGetAutomaticallyDefaultRoles] = useState(props.data.common.getAutomaticallyDefaultRoles);
    const isMounted = useRef(false);
    const isMountedAllowedRoles = useRef(false);
    const isMountedDefaultRoles = useRef(false);
    const refRoleMenu = useRef<null | HTMLDivElement>(null);
    const refDefaultRoleMenu = useRef<null | HTMLDivElement>(null);
    const dataToUpdate = {
        prefix,
        canUseCommands,
        canUseSlashCommands,
        id: props.data.id
    };
    const [debouncedData] = useDebounce(JSON.stringify(dataToUpdate), 500);
    const [debouncedAllowedRoleData] = useDebounce(JSON.stringify({allowedRoles: allowedRoles.map(elem => elem.id), id: props.data.id}), 500);
    const [debouncedDefaultRoleData] = useDebounce(JSON.stringify({getAutomaticallyDefaultRoles, defaultRoles: defaultRoles.map(elem => elem.id), id: props.data.id}), 500);
    function checkContrast(backgroundColor: string, textColor: string) {
        const contrastRatio = chroma.contrast(backgroundColor, textColor);
      
        // Проверяем, соответствует ли контраст требованиям WCAG
        const isSufficientContrast = contrastRatio >= 4.5; // Для большого текста
      
        return isSufficientContrast;
      }
      
    useEffect(() => {
        (async() => {
            if (!isMounted.current) {
                isMounted.current = true;
                return;
              }
            if (debouncedData && cookies.user_token) {
              const response = await fetch('https://omnibot.su/api/save-common-main-data', {
                method: 'POST',
                headers: {
                    "Authorization": cookies.user_token,
                    "Content-Type": "application/json"
                },
                body: debouncedData,
              });
              if (response.status === 200 && refSaved.current) {
                const elem = refSaved.current as HTMLDivElement;
                elem.style.opacity = '1';
                setTimeout(() => {
                    elem.style.opacity = '0';
                }, 2_000)
              }
            }
        })()
      }, [debouncedData, cookies.user_token]);
    useEffect(() => {
    (async() => {
        if (!isMountedAllowedRoles.current) {
            isMountedAllowedRoles.current = true;
            return;
            }
        if (debouncedAllowedRoleData && cookies.user_token) {
            const response = await fetch('https://omnibot.su/api/save-common-allowed-roles-data', {
            method: 'POST',
            headers: {
                "Authorization": cookies.user_token,
                "Content-Type": "application/json"
            },
            body: debouncedAllowedRoleData,
            });
            if (response.status === 200 && refSavedAllowedRoles.current) {
            const elem = refSavedAllowedRoles.current as HTMLDivElement;
            elem.style.opacity = '1';
            setTimeout(() => {
                elem.style.opacity = '0';
            }, 2_000)
            }
        }
    })()
    }, [debouncedAllowedRoleData, cookies.user_token]);
    useEffect(() => {
        (async() => {
            if (!isMountedDefaultRoles.current) {
                isMountedDefaultRoles.current = true;
                return;
                }
            if (debouncedDefaultRoleData && cookies.user_token) {
                const response = await fetch('https://omnibot.su/api/save-common-default-roles-data', {
                method: 'POST',
                headers: {
                    "Authorization": cookies.user_token,
                    "Content-Type": "application/json"
                },
                body: debouncedDefaultRoleData,
                });
                if (response.status === 200 && refSavedDefaultRoles.current) {
                const elem = refSavedDefaultRoles.current as HTMLDivElement;
                elem.style.opacity = '1';
                setTimeout(() => {
                    elem.style.opacity = '0';
                }, 2_000)
                }
            }
        })()
    }, [debouncedDefaultRoleData, cookies.user_token]);
    const inputFocus = () => {
        if (!refCount.current) return;
        const elem = refCount.current as HTMLDivElement;
        elem.style.transform = 'translateY(0)';
        elem.style.opacity = '1';
    }
    const inputBlur = () => {
        if (!refCount.current) return;
        const elem = refCount.current as HTMLDivElement;
        elem.style.transform = 'translateY(-10px)';
        elem.style.opacity = '0';
        if (prefix.length === 0) {
            setPrefix('!')
        }
    }
    const changeCommands = () => {
        startTransition(() => {
            setCanUseCommands(!canUseCommands)
        })
    }
    const changeSlashCommands = () => {
        startTransition(() => {
            setCanUseSlashCommands(!canUseSlashCommands)
        })
    }
    const changeGetDefaultRoles = () => {
        startTransition(() => {
            setGetAutomaticallyDefaultRoles(!getAutomaticallyDefaultRoles)
        })
    }
    const mouseOverSave = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
        if (!ref.current) return;
        const elem = ref.current as HTMLDivElement;
        elem.style.width = '130px';
        const mark = elem.children[0] as SVGElement;
        mark.style.marginLeft = '5px';
    }
    const mouseOutSave = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
        if (!ref.current) return;
        const elem = ref.current as HTMLDivElement;
        elem.style.width = '20px';
        const mark = elem.children[0] as SVGElement;
        mark.style.marginLeft = '-105px';
    }
    const closeRoleModal = (elem: HTMLElement, type: 'allowed' | 'default') => {
        elem.style.opacity = '0';
        elem.style.scale = '0.3';
        elem.style.left = '-200px';
        elem.style.top = '-100px';
        if (type === 'allowed') {
            document.removeEventListener('click', clickEvent);
            setIsRoleMenuOpen(false);
        } else {
            document.removeEventListener('click', clickEventDefault);
            setIsDefaultRoleMenuOpen(false);
        }
    }
    const clickEvent = async (event: MouseEvent) => {
        let target = event.target as HTMLElement;
        if (!refRoleMenu.current) return;
        const elem = refRoleMenu.current as HTMLDivElement;
        if (target !== elem){
            while ((target = target.parentElement as HTMLElement)) {
                if (target === elem) return;
            }
            closeRoleModal(elem, 'allowed');
        }
    }
    const clickEventDefault = async (event: MouseEvent) => {
        let target = event.target as HTMLElement;
        if (!refDefaultRoleMenu.current) return;
        const elem = refDefaultRoleMenu.current as HTMLDivElement;
        if (target !== elem){
            while ((target = target.parentElement as HTMLElement)) {
                if (target === elem) return;
            }
            closeRoleModal(elem, 'default');
        }
    }
    const showRoleMenu = async () => {
        if (!cookies.user_token || !refRoleMenu.current || !props.data.common.isOwner) return;
        const elem = refRoleMenu.current as HTMLDivElement;
        if (isRoleMenuOpen) {
            closeRoleModal(elem, 'allowed');
        } else {
            setIsRoleMenuOpen(true);
            elem.style.opacity = '1';
            elem.style.scale = '1';
            elem.style.left = '-300px';
            elem.style.top = '-30px';
            const response = await (await fetch(`https://omnibot.su/api/getServerRoles?guildId=${props.data.id}&adm=1`, {
                headers: {
                    "Authorization": cookies.user_token
                },
              })).json();
              const roles = response.filter((r: GuildRoles) => !allowedRoles.map(e => e.id).includes(r.id));
              setServerRoles(roles);
            document.addEventListener('click', clickEvent)
        }
        
    }
    const showDefaultRoleMenu = async () => {
        if (!cookies.user_token || !refDefaultRoleMenu.current) return;
        const elem = refDefaultRoleMenu.current as HTMLDivElement;
        if (isDefaultRoleMenuOpen) {
            closeRoleModal(elem, 'default');
        } else {
            setIsDefaultRoleMenuOpen(true);
            elem.style.opacity = '1';
            elem.style.scale = '1';
            elem.style.left = '-300px';
            elem.style.top = '-30px';
            const response = await (await fetch(`https://omnibot.su/api/getServerRoles?guildId=${props.data.id}&adm=0`, {
                headers: {
                    "Authorization": cookies.user_token
                },
              })).json();
              const roles = response.filter((r: GuildRoles) => !defaultRoles.map(e => e.id).includes(r.id));
              setServerDefaultRoles(roles);
            document.addEventListener('click', clickEventDefault)
        }
        
    }
    const selectRole = (role: GuildRoles, type: 'default' | 'allowed') => {
        if (type === 'default') {
            setDefaultRoles([...defaultRoles, role]);
            if (serverDefaultRoles) {
                const roles = serverDefaultRoles.filter((r: GuildRoles) => ![...defaultRoles, role].map(e => e.id).includes(r.id));
                setServerDefaultRoles(roles);
            }
        } else {
            setAllowedRoles([...allowedRoles, role]);
            if (serverRoles) {
                const roles = serverRoles.filter((r: GuildRoles) => ![...allowedRoles, role].map(e => e.id).includes(r.id));
                setServerRoles(roles);
            }
        }
    }
    const deleteRole = (role: GuildRoles, type: 'default' | 'allowed') => {
        if (type === 'default') {
            setDefaultRoles(defaultRoles.filter(r => r.id !== role.id));
            if (serverDefaultRoles) {
                setServerDefaultRoles([...serverDefaultRoles, role]);
            }
        } else if (type === 'allowed') {
            if (!props.data.common.isOwner) return;
            setAllowedRoles(allowedRoles.filter(r => r.id !== role.id));
            if (serverRoles) {
                setServerRoles([...serverRoles, role]);
            }
        }
    }
    return (
        <div className={styles.common}>
            <div className={styles.main}>
                <span className={styles.title}>Основное</span>
                <div className={styles.prefixWrap}>
                    <div className={styles.prefix}>Префикс</div>
                    <input onFocus={inputFocus} onBlur={inputBlur} value={prefix} onChange={(e) => setPrefix(e.target.value)} type="text" className={styles.inputPrefix} maxLength={10} />
                    <div className={styles.count} ref={refCount}>{prefix.length}/10</div>
                </div>
                <div className={styles.mark}>
                    {canUseCommands ? (<CheckMarkFullSvgComponent className={styles.markSvg} onClick={changeCommands}/>) : (<CheckMarkSvgComponent className={styles.markSvg} onClick={changeCommands}/>)}
                    <div className={styles.text}>Включить команды на сервере</div>
                </div>
                <div className={styles.mark}>
                    {canUseSlashCommands ? (<CheckMarkFullSvgComponent className={styles.markSvg} onClick={changeSlashCommands}/>) : (<CheckMarkSvgComponent className={styles.markSvg} onClick={changeSlashCommands}/>)}
                    <div className={styles.text}>Включить слэш команды на сервере</div>
                </div>
                <div onMouseOver={() => mouseOverSave(refSaved)} onMouseOut={() => mouseOutSave(refSaved)} ref={refSaved} className={styles.saved}>Сохранено <MarkSvgComponent className={styles.markSvg}/></div>
            </div>
            <div className={props.data.common.isOwner ?  styles.allowedRole : `${styles.allowedRole} ${styles.noOwnerRole}`}>
                <span className={styles.title}>Доверенные роли Администраторов</span>
                <span className={styles.postfix}>Если не указано ничего, значит панель доступна всем Администраторам</span>
                {allowedRoles.map((role, id) => {
                    return (
                        <div key={id} className={styles.elem}>
                            <div onClick={() => deleteRole(role, 'allowed')} className={styles.circle} style={{backgroundColor: role.color}}><CrossSvgComponent className={styles.crossSvg} styles={{stroke: checkContrast(role.color, '#000000') ? '#000000' : '#ffffff'}}/></div>
                            {role.icon && (<img className={styles.icon} src={role.icon}/>)}
                            <div className={styles.name}>{role.name}</div>
                        </div>
                    )
                })}
                <div onClick={showRoleMenu} className={styles.plus}>
                    <PlusSvgComponent className={styles.plusSvg}/>
                </div>
                <div ref={refRoleMenu} className={styles.roleMenu}>
                        {serverRoles[0] ? (
                            <>
                            {serverRoles.map((role, id) => {
                                return (
                                    <div onClick={() => selectRole(role, 'allowed')} key={id} className={styles.elem}>
                                        <div className={styles.circle} style={{backgroundColor: role.color}}></div>
                                        {role.icon && (<img className={styles.icon} src={role.icon}/>)}
                                        <div className={styles.name}>{role.name}</div>
                                    </div>
                                )
                            })}
                            </>
                        ) : null}
                    </div>
                    <div onMouseOver={() => mouseOverSave(refSavedAllowedRoles)} onMouseOut={() => mouseOutSave(refSavedAllowedRoles)} ref={refSavedAllowedRoles} className={styles.saved}>Сохранено <MarkSvgComponent className={styles.markSvg}/></div>
            </div>
            <div className={styles.newMembers}>
                <span className={styles.title}>Новые участники</span>
                <span className={styles.titleDefault}>Стандартная роль</span>
                    <div className={styles.defaultRole}>
                        {defaultRoles.map((role, id) => {
                            return (
                                <div key={id} className={styles.elem}>
                                    <div onClick={() => deleteRole(role, 'default')} className={styles.circle} style={{backgroundColor: role.color}}><CrossSvgComponent className={styles.crossSvg} styles={{stroke: checkContrast(role.color, '#000000') ? '#000000' : '#ffffff'}}/></div>
                                    {role.icon && (<img className={styles.icon} src={role.icon}/>)}
                                    <div className={styles.name}>{role.name}</div>
                                </div>
                            )
                        })}
                        <div onClick={showDefaultRoleMenu} className={styles.plus}>
                            <PlusSvgComponent className={styles.plusSvg}/>
                        </div>
                </div>
                <div className={styles.mark}>
                    {getAutomaticallyDefaultRoles ? (<CheckMarkFullSvgComponent className={styles.markSvg} onClick={changeGetDefaultRoles}/>) : (<CheckMarkSvgComponent className={styles.markSvg} onClick={changeGetDefaultRoles}/>)}
                    <div className={styles.text}>Выдавать эти роли автоматически</div>
                </div>
                <div ref={refDefaultRoleMenu} className={styles.roleMenu}>
                                {serverDefaultRoles[0] ? (
                                    <>
                                    {serverDefaultRoles.map((role, id) => {
                                        return (
                                            <div onClick={() => selectRole(role, 'default')} key={id} className={styles.elem}>
                                                <div className={styles.circle} style={{backgroundColor: role.color}}></div>
                                                {role.icon && (<img className={styles.icon} src={role.icon}/>)}
                                                <div className={styles.name}>{role.name}</div>
                                            </div>
                                        )
                                    })}
                                    </>
                                ) : null}
                            </div>
                        <div onMouseOver={() => mouseOverSave(refSavedDefaultRoles)} onMouseOut={() => mouseOutSave(refSavedDefaultRoles)} ref={refSavedDefaultRoles} className={styles.saved}>Сохранено <MarkSvgComponent className={styles.markSvg}/></div>
            </div>
        </div>
    )
}
export {Common}