import React from "react";

function PlusSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.5H1M8.5 1V16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>




    )
}

export {PlusSvgComponent};