import React, { useEffect, useRef, useState, memo } from "react";
import { useCookies } from "react-cookie";
import styles from './Header.module.scss';
import {DoscSvgComponent} from '../assets/dosc';
import { CrownSvgComponent } from "../assets/crown";
import { StatsSvgComponent } from "../assets/stats";
import { LoginSvgComponent } from "../assets/login";
import { LogOutSvgComponent } from "../assets/logout";
import { useNavigate } from "react-router-dom";
type UserInfo = {
    id: string;
    avatar: string;
    displayName: string;
}
const Header = memo(function Header(): React.JSX.Element {
    const [cookies,, removeCookies] = useCookies(["user_token"]);
    const [dataUser, setDataUser] = useState<false | UserInfo>(false);
    const refAvatar = useRef<null | HTMLImageElement>(null);
    const refTooltip = useRef<null | HTMLDivElement>(null);
    const refUserMenu = useRef<null | HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const authorizateBtn = () => {
        window.open("https://discord.com/oauth2/authorize?client_id=1268113414530797588&response_type=code&redirect_uri=https%3A%2F%2Fomnibot.su%2Fauth%2F&scope=identify+guilds+guilds.members.read",
            "_blank", 'location=yes,height=900,width=680,scrollbars=no,status=yes')
    }
    let timeout: NodeJS.Timeout
    const handleMouseOver = () => {
        timeout = setTimeout(() => {
          if (refTooltip.current && !menuOpen) {
            refTooltip.current.style.opacity = '1';
            refTooltip.current.style.transform = 'translateY(0) scale(1)';
        }
        }, 800); 
      };
      const handleMouseOut = () => {
        clearTimeout(timeout);
        if (refTooltip.current) {
            refTooltip.current.style.opacity = '0';
            refTooltip.current.style.transform = 'translateY(-50%) scale(0.5)';
        }
      };
    const menu = () => {
        if (!refUserMenu.current) return;
        if (menuOpen) {
            refUserMenu.current.style.opacity = '0';
        } else {
            refUserMenu.current.style.opacity = '1';
            if (refTooltip.current) {
                refTooltip.current.style.opacity = '0';
                refTooltip.current.style.transform = 'translateY(-50%) scale(0.5)';
                clearTimeout(timeout);
            }
        }
        setMenuOpen(!menuOpen);
    }
    const logOut = () => {
        removeCookies("user_token");
        navigate("/", { state: { scroll: false } });
      }
    useEffect(() => {
        (async () => {
            if (cookies.user_token) {
                const userInfo: UserInfo = await (await fetch(`https://omnibot.su/api/userInfo/`, {
                    headers: {
                        "Authorization": cookies.user_token
                    }
                })).json();
                setDataUser(userInfo);
            } else {
                return setDataUser(false)
            }
        })()
    }, [cookies.user_token])
    return (
      <header className={styles.welcomeHeader}>
        <div className={styles.leftPart}>
            <button type="submit" className={styles.omni} onClick={() => navigate('/', { state: { scroll: false } })}>OMNI</button>
            <button type="submit" className={styles.nav}><DoscSvgComponent className={styles.doscSvg}/>ДОКУМЕНТАЦИЯ</button>
            <button type="submit" className={styles.nav}><StatsSvgComponent className={styles.statsSvg}/>СТАТИСТИКА</button>
            <button type="submit" className={styles.nav}><CrownSvgComponent className={styles.crownSvg}/>ПРЕМИУМ</button>
        </div>
        <div className={styles.rightPart}>
            {dataUser ? (
            <div className={styles.user}>
                <img onClick={menu} onMouseEnter={handleMouseOver} onMouseOut={handleMouseOut} ref={refAvatar} className={styles.userAvatar} src={`https://cdn.discordapp.com/avatars/${dataUser.id}/${dataUser.avatar}.png`} alt="" />
                <div ref={refTooltip} className={styles.tooltip}>{dataUser.displayName}</div>
                <div ref={refUserMenu} className={styles.userMenu}>
                    <div className={styles.name}>{dataUser.displayName}</div>
                    <span className={styles.line}></span>
                    <div onClick={logOut} className={styles.logOut}>< LogOutSvgComponent className={styles.svg} />Выйти</div>
                </div>
            </div>
            ) : (
                <button className={styles.authBtn} type="submit" onClick={authorizateBtn}><LoginSvgComponent className={styles.loginSvg}/>ВО<span style={{fontSize: '1.27em'}}>й</span>ТИ</button>
            )}
        </div>
      </header>
    );
})

export {Header}