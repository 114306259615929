import React from "react";
import styles from './MainPanel.module.scss';
import { Common, Commands } from "./Components";
import { GuildData } from "../../types";
import { LoadingSvgComponent } from '../../assets/loading';
function MainPanel(props: {type: 'common' | 'commands' | 'music' | 'economic' | 'rating' | 'audit', data: GuildData | null}): React.JSX.Element {
    return (
        <div className={styles.mainPanel}>
            {props.data ? (
                <>
                {props.type === 'common' && (<Common data={{id: props.data.id, common: props.data.common}}/>)} 
                {props.type === 'commands' && (<Commands data={{id: props.data.id, commands: props.data.commands}}/>)} 
                </>
            ) : <LoadingSvgComponent className={styles.loading}/>}
        </div>
    )
}

export {MainPanel}