import React from "react";

function CrownSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.975 17.5H4.025C3.70875 17.5 3.45 17.7812 3.45 18.125V19.375C3.45 19.7188 3.70875 20 4.025 20H18.975C19.2913 20 19.55 19.7188 19.55 19.375V18.125C19.55 17.7812 19.2913 17.5 18.975 17.5ZM21.275 5C20.3227 5 19.55 5.83984 19.55 6.875C19.55 7.15234 19.6075 7.41016 19.7081 7.64844L17.1062 9.34375C16.5528 9.70312 15.8377 9.5 15.5178 8.89062L12.5889 3.32031C12.9734 2.97656 13.225 2.46094 13.225 1.875C13.225 0.839844 12.4523 0 11.5 0C10.5477 0 9.775 0.839844 9.775 1.875C9.775 2.46094 10.0266 2.97656 10.4111 3.32031L7.48219 8.89062C7.16234 9.5 6.44359 9.70312 5.89375 9.34375L3.29547 7.64844C3.3925 7.41406 3.45359 7.15234 3.45359 6.875C3.45359 5.83984 2.68094 5 1.72859 5C0.77625 5 0 5.83984 0 6.875C0 7.91016 0.772656 8.75 1.725 8.75C1.81844 8.75 1.91188 8.73438 2.00172 8.71875L4.6 16.25H18.4L20.9983 8.71875C21.0881 8.73438 21.1816 8.75 21.275 8.75C22.2273 8.75 23 7.91016 23 6.875C23 5.83984 22.2273 5 21.275 5Z" fill="#F8F8F8"/>
        </svg>

    )
}

export {CrownSvgComponent};