import React from "react";

function FindSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8582 34.5738C23.0263 34.5738 27.0236 32.9181 29.9708 29.9708C32.9181 27.0236 34.5738 23.0263 34.5738 18.8582C34.5738 14.6902 32.9181 10.6929 29.9708 7.74564C27.0236 4.79839 23.0263 3.14265 18.8582 3.14265C14.6902 3.14265 10.6929 4.79839 7.74564 7.74564C4.79839 10.6929 3.14265 14.6902 3.14265 18.8582C3.14265 23.0263 4.79839 27.0236 7.74564 29.9708C10.6929 32.9181 14.6902 34.5738 18.8582 34.5738ZM33.4611 30.7926L44 41.3315L41.3315 44L30.7926 33.4611C27.0465 36.5227 22.2669 38.0275 17.4425 37.6641C12.618 37.3008 8.11776 35.0972 4.87236 31.5091C1.62697 27.921 -0.115284 23.2228 0.00592557 18.3862C0.127135 13.5496 2.10254 8.94464 5.52359 5.52359C8.94464 2.10254 13.5496 0.127135 18.3862 0.00592557C23.2228 -0.115284 27.921 1.62697 31.5091 4.87236C35.0972 8.11776 37.3008 12.618 37.6641 17.4425C38.0275 22.2669 36.5227 27.0465 33.4611 30.7926Z" fill="white"/>
        </svg>
    )
}

export {FindSvgComponent};