import React, { useEffect, useRef, useState } from 'react';
import GuildCards from './GuildsCards';
// import styles from './GuildsApp.module.css';
import styles from './GuildsApp.module.scss';
// import { DiscordSvgComponent } from '../assets/discord';
import { FindSvgComponent } from '../assets/find';
import chooseServer from '../assets/chooseServer.png'
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useLocation } from 'react-router-dom';
function GuildApp():React.JSX.Element {
  const refMain = useRef<null | HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [query, setQuery] = useState('')
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const location = useLocation();
  useEffect(() => {
      if (location.state && location.state.scroll === false) {
          window.scrollTo(0, 0);
      }
  }, [location]);
  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setQuery(e.target.value);
    }, 800);
    
  }
  useEffect(() => {
    if (!refMain.current) return;
    const mainRef = refMain.current;
    mainRef.addEventListener('contextmenu', event => event.preventDefault());
    mainRef.addEventListener('dragstart', event => event.preventDefault());
    return () => {
      if (!mainRef) return;
      mainRef.removeEventListener('contextmenu', () => {});
      mainRef.removeEventListener('dragstart', () => {});
    }
  }, [])

  return (
    <>
      <Header />
      <main ref={refMain} className={styles.main}>
        <img className={styles.choosePng} src={chooseServer} alt="" />
        {/* <div className={styles.chooseText}>Выберите <DiscordSvgComponent className={styles.discordSvg}/> сервер</div> */}
        <div className={styles.inputWrap}>
          <input value={inputValue} onChange={inputOnChange} className={styles.input} type="text" placeholder='Найти сервер..' />
          <FindSvgComponent className={styles.findSvg}/>
        </div>
        <GuildCards query={query}/>
      </main>
      <Footer />
    </>
  );
}

export { GuildApp };
