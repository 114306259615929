import React from "react";

function DoscSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.6476 2.60156C8.6476 3.70613 9.54303 4.60156 10.6476 4.60156H13L8.6476 0V2.60156ZM10 18C11.6569 18 13 16.6569 13 15V6H10.4286C8.77172 6 7.42857 4.65685 7.42857 3V0H3C1.34314 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H10Z" fill="#F8F8F8"/>
        </svg>
    )
}
export {DoscSvgComponent};
