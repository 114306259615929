import React, { useEffect, useRef, useState } from 'react';
import { FindSvgComponent } from '../../../assets/find';
import { CommandsData } from '../../../types';
import styles from './Commands.module.scss';
import { useDebounce } from 'use-debounce';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function Commands(props: {data: CommandsData}): React.JSX.Element {
    const [inputValue, setInputValue] = useState('');
    const [commands, setCommands] = useState(props.data.commands);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletedCommands, setDeletedCommands] = useState<CommandsData["commands"]>([]);
    const [debouncedData] = useDebounce(JSON.stringify(commands), 500);
    const isMounted = useRef(false);
    const [cookies] = useCookies(['user_token']);
    const navigate = useNavigate();
    useEffect(() => {
        (async() => {
            if (!isMounted.current) {
                isMounted.current = true;
                return;
              }
            if (debouncedData && cookies.user_token) {
            //   await fetch('https://omnibot.su/api/save-common-main-data', {
            //     method: 'POST',
            //     headers: {
            //         "Authorization": cookies.user_token,
            //         "Content-Type": "application/json"
            //     },
            //     body: debouncedData,
            //   });
            }
        })()
      }, [debouncedData, cookies.user_token]);
    const deletingBtn = () => {
        setIsDeleting(!isDeleting);
    }
    const commandClicked = (elem: CommandsData["commands"][0]) => {
        if (isDeleting) {
            setDeletedCommands([...deletedCommands, elem]);
            setCommands(commands.filter((cmd) => cmd.id !== elem.id));
            setIsDeleting(false);
        } else {
            navigate(`/guilds/${props.data.id}/${elem.id}`, { state: { scroll: false } })
        }
    }
    const cancelDelete = () => {
        const deleted = deletedCommands.shift();
        if (deleted) {
            setDeletedCommands(deletedCommands.filter((cmd) => cmd.id !== deleted.id));
            setCommands([...commands, deleted]);
        }
    }
    return (
        <div className={styles.commands}>
            <div className={styles.part1}>
                <button onClick={deletingBtn} className={isDeleting ? `${styles.deleteCommand} ${styles.deletingBtn}` : styles.deleteCommand}>{isDeleting ? 'Отменить удаление' : 'Удалить команду'}</button>
                {deletedCommands.length ? <button onClick={cancelDelete} className={styles.cancelDeleteCommand}>Отменить удаление</button> : null}
            </div>
            <div className={styles.part2}>
                <div className={styles.inputWrap}>
                    <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={styles.input} type="text" placeholder='Найти команду..' />
                    <FindSvgComponent className={styles.findSvg}/>
                </div>
                {commands.length ? commands.map((elem, key) => (
                    <div onClick={() => commandClicked(elem)} className={isDeleting ? `${styles.command} ${styles.deleting}` : styles.command} key={key}>
                        {elem.isSlashCommand && (<span className={styles.slash}>/</span>)}
                        <span className={styles.name}>{elem.name}</span>
                        {elem.arguments ? elem.arguments.map((arg, argKey) => (
                            <span className={styles.argument} key={argKey}>
                                {arg.name}:
                                <span className={styles.argType}>{arg.type}</span>
                            </span>
                        )) : null}
                        <span className={styles.description}>{elem.description}</span>
                    </div>
                )) : null}
            </div>
            <div className={styles.part3}>
                <button className={styles.addNewCommand}>Добавить новую команду</button>
            </div>
        </div>
    )
}

export {Commands}