import React from "react";

function CheckMarkFullSvgComponent(props: { className?: string, onClick: React.MouseEventHandler<SVGElement> }): React.JSX.Element {
    return (
        <svg onClick={(event: React.MouseEvent<SVGElement>) => props.onClick && props.onClick(event)} className={props.className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_98_133)">
                <path d="M31 6L17.8462 20L12 13.7778" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33 15.7778V31.2778C33 31.7345 32.8186 32.1726 32.4956 32.4956C32.1726 32.8186 31.7345 33 31.2778 33H3.72222C3.26546 33 2.82741 32.8186 2.50443 32.4956C2.18145 32.1726 2 31.7345 2 31.2778V3.72222C2 3.26546 2.18145 2.82741 2.50443 2.50443C2.82741 2.18145 3.26546 2 3.72222 2H24.3889" stroke="#D0D0D0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_98_133">
                <rect width="35" height="35" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
}

export {CheckMarkFullSvgComponent};