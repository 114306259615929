import React from "react";

function UserSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4.26316C4 6.61358 5.79467 8.52632 8 8.52632C10.2053 8.52632 12 6.61358 12 4.26316C12 1.91274 10.2053 0 8 0C5.79467 0 4 1.91274 4 4.26316ZM15.1111 18H16V17.0526C16 13.3967 13.208 10.4211 9.77778 10.4211H6.22222C2.79111 10.4211 0 13.3967 0 17.0526V18H15.1111Z" fill="white"/>
        </svg>
    )
}

export {UserSvgComponent};