import React, { useEffect } from "react";
import styles from './TermsOfUse.module.scss';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useLocation } from "react-router-dom";
function TermsOfUse(): React.JSX.Element {
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.scroll === false) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return (
        <>
        <Header />
        <main className={styles.main}>
            <div className={styles.conf}>
                <div className={styles.omni}>Условия использования</div>
                <span>1. Введение</span>
                <p>Используя веб-сайт Omni https://omnibot.su и данного бота Discord, Вы соглашаетесь соблюдать настоящие условия использования, условия использования Discord, все  применимые законы и регламенты, а также соглашаетесь с тем, что несете  полную ответственность за соблюдение местного законодательства. Если Вы  не согласны с каким-либо из этих условий, Вам запрещается использование данного сайта и связанных с ним ресурсов.</p>
                <span>2. Соглашение об использовании</span>
                <ol>
                    <li>Разрешается временное получение единственной копии материалов (информации или ПО) на сайте https://omnibot.su для личного и некоммерческого просмотра.</li>
                    <li>Данное соглашение не передаёт права собственности, и в соответствии с ним Вы не можете:</li>
                    <ul>
                        <li>Модифицировать или копировать материалы.</li>
                        <li>Использовать материалы в коммерческих целях или для публичного показа (коммерческого или некоммерческого).</li>
                        <li>Пытаться декомпилировать или заниматься обратной разработкой (реверс-инжинирингом) любого ПО, размещенного на веб-сайте https://omnibot.su и связанных ресурсах.</li>
                        <li>Удалять любые авторские права или другие права собственности из материалов.</li>
                        <li>Передавать материалы третьим лицам или создавать “зеркала” материалов на любом другом сервере.</li>
                    </ul>
                    <li>Данное Соглашение будет автоматически аннулировано, если Вы нарушите любое из этих ограничений и может быть аннулировано со стороны Omni в любой момент в одностороннем порядке. После прекращения просмотра этих материалов или прекращения действия данного соглашения Вы должны немедленно уничтожить все загруженные материалы, имеющиеся в Вашем распоряжении в электронном или печатном виде.</li>
                </ol>
                <span>3. Отказ от ответственности</span>
                <ol>
                    <li>Материалы на веб-сайте https://omnibot.su предоставлены “как есть”, без каких-либо гарантий, выраженных или подразумеваемых, включая, но не ограничиваясь, гарантий пригодности и соответствия конкретной цели и соблюдения прав интеллектуальной собственности.</li>
                    <li>Omni не дает никаких гарантий и не делает никаких заявлений относительно точности, вероятных результатов или надежности использования материалов на веб-сайте, иными связями с данными материалами или на любых ресурсах, связанных с данным сайтом.</li>
                    <li>Omni и его представители не несут ответственности за возможные разрушительные действия ПО на Вашем сервере Discord вследствие невнимательности и/или халатности администрации этого сервера или неправильной настройки функциональности. Все настройки данного бота предоставлены “как есть” и их использование допускается исключительно на страх и риск конечных пользователей.</li>
                </ol>
                <span>4. Ограничения</span>
                <p>Omni и его представители ни при каких условиях не несут ответственности за какие-либо убытки (включая, но не ограничиваясь, убытки от потери данных или прибыли или из-за прерывания работы), возникшие в результате использования или невозможности использования материалов на веб-сайте https://omnibot.su и связанных с ним ресурсов, даже если Omni или уполномоченный представитель данного бота уведомили устно или письменно о возможности такого повреждения.</p>
                <span>5. Точность материалов</span>
                <p>Материалы, предоставляемые веб-сайтом https://omnibot.su, могут содержать технические, типографические или фотографические ошибки. Omni не гарантирует точность, полноту и актуальность предоставляемых материалов. Также, Omni может вносить изменения в материалы, содержащиеся на сайте и ресурсах, в любое время без предварительного уведомления. Однако, Omni не дает никаких обязательств по обновлению данных материалов.</p>
                <span>6. Ссылки и вложения</span>
                <p>Omni не проверяет все предоставляемые на данном сайте ссылки и вложения и не несёт ответственности за их содержимое. Возможность добавления любых ссылок и вложений не подразумевает их одобрение Omni. Использование таких ссылок и вложений осуществляется на страх и риск конечного пользователя.</p>
                <span>7. Изменения</span>
                <p>Omni может пересматривать данные условия использования в любое время без предварительного уведомления. Используя данный веб-сайт и связанные с ним ресурсы, Вы соглашаетесь с действующей на данный момент версией условий использования.</p>
                <span>8. Регулирующее законодательство</span>
                <p>Данные условия использования регулируются и толкуются в соответствии с законодательством Российской Федерации и Вы безоговорочно подчиняетесь законодательству и исключительной юрисдикции судов этого государства.</p>
                <span>9. Свяжитесь с нами</span>
                <p>Если у Вас возникли вопросы по Условиям использования,  обратитесь к нам по адресу support@omnibot.su</p>
            </div>
        </main>
        <Footer />
        </>
    )
}

export {TermsOfUse}