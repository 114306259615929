import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import disocrdpng from "../public/discord.png";
// import styles from './GuildsApp.module.css';
import styles from './GuildsCards.module.scss';
import React from "react";
import { IGuild } from "./Interfaces";
import { UserSvgComponent } from "../assets/user";
import { useNavigate } from "react-router-dom";

export default function GuildCards(props: {query: string}):React.JSX.Element {
    const [cookies] = useCookies(["user_token"]);
    const [dataCards, setDatacards] = useState<null | IGuild[]>(null);
    const [columsLoading, setColumsLoading] = useState([0]);
    const navigate = useNavigate();
    const getInvLink = (id:string) => {
        window.open(`https://discord.com/oauth2/authorize?client_id=1268113414530797588&permissions=8&integration_type=0&scope=bot+applications.commands&guild_id=${id}`,
        "_blank", 'location=yes,height=850,width=680,scrollbars=yes,status=yes')
    }
    const goListCommands = (id:string) => {
        navigate(`/guilds/${id}`)
    }
    useEffect(() => {
        (async() => {
            if (!cookies.user_token) location.replace('/');
            setDatacards(null);
            const guilds = await (await fetch(`https://omnibot.su/api/guilds/?query=${props.query}`, {
                headers: {
                    "Authorization": cookies.user_token,
                    "Content-Type": "application/json",
                }
            })).json()
            setDatacards(guilds);
        })()
      }, [cookies.user_token, props.query]);
    useEffect(() => {
        if (window.innerWidth < 1640) {
            setColumsLoading([0, 1, 2, 3, 4, 5])
        } else if (window.innerWidth < 1230) {
            setColumsLoading([0, 1, 2, 3])
        } else {
            setColumsLoading([0, 1, 2, 3, 4, 5, 6, 7])
        }
    }, [])

    return (
        <>
        <div className={styles.cards} onMouseOver={() => document.body.style.overflow = 'hidden'} onMouseOut={() => document.body.style.overflow = 'auto'}>
            {!dataCards ? columsLoading.map((_v, index) => {
                return (<div key={index} className={styles.loadingCard} > 
                <div className={styles.wave}></div>
            </div>)
            }) : dataCards.map((value, ind) => {
                return (<div key={ind} className={styles.card} onClick={() => value.hasBot ? goListCommands(value.id) : getInvLink(value.id)}> 
                    <img className={styles.img} src={ value.icon ? `https://cdn.discordapp.com/icons/${value.id}/${value.icon}` : disocrdpng} alt="" />
                    <div className={styles.cardsCnt}>
                        <div className={styles.guildName}>{value.name}</div>
                        { value.hasBot ? 
                        <div className={styles.online}><UserSvgComponent className={styles.userSvg}/>Онлайн: {value.onlineMembers}</div> :
                        // <button className={styles.goCommands} type="submit" onClick={() => goListCommands(value.id)}>Перейти</button> :
                        <button className={styles.addBotToGuild} type="submit">Пригласить</button> }
                    </div>
                </div>)
              })}
              {(dataCards && dataCards[0] === undefined) ? <div className={styles.noResults}>Нет результатов</div> : null}
        <div className={styles.closer}></div>
        </div>
        </>
    );
}