import React, { useEffect } from "react";
import styles from './Cookie.module.scss';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useLocation } from "react-router-dom";
function Cookie(): React.JSX.Element {
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.scroll === false) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return (
        <>
        <Header />
        <main className={styles.main}>
            <div className={styles.conf}>
                <div className={styles.omni}>Файлы cookie</div>
                <p>Эта страница предоставляет информацию о том, как наш сайт https://omnibot.su использует файлы cookie и подобные технологии отслеживания.</p>
                <span>Что такое файлы cookie?</span>
                <p>Файлы cookie — это небольшие текстовые файлы, которые сайты сохраняют на компьютере или мобильном устройстве пользователя при посещении сайта. Они позволяют сайту запоминать действия и предпочтения пользователя (например, язык, размер шрифта и другие настройки), чтобы в следующий раз, когда пользователь вернется на этот сайт, ему не пришлось снова вводить эту информацию.</p>
                <span>Как мы используем файлы cookie?</span>
                <p>Наш сайт использует файлы cookie для хранения зашифрованного токента авторизации</p>
                <span>Как отключить cookie?</span>
                <p>Большинство браузеров позволяют вам отключить файлы cookie или получать уведомление, когда сайт пытается установить cookie. Однако, отключение файлов cookie будет означать невозможным использовать сайт полностью.</p>
            </div>
        </main>
        <Footer />
        </>
    )
}

export {Cookie}