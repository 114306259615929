import React from "react";

function LoginSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="23" height="22" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2917 38.1666H37.9583C40.3977 38.1666 42.375 36.1892 42.375 33.7499V7.24992C42.375 4.81066 40.3977 2.83325 37.9583 2.83325H20.2917M2.625 20.4999H26.9167M26.9167 20.4999L20.2917 27.1249M26.9167 20.4999L20.2917 13.8749" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export {LoginSvgComponent};