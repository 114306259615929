import React from "react";

function DiscordSvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="173" height="134" viewBox="0 0 173 134" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* <defs>
                <filter id="blur-small">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="0.8" />
                </filter>
                <filter id="blur-large">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter>
            </defs> */}
            <path d="M146.23 11.8833C135.077 6.71503 123.199 2.90683 110.686 0.730713C109.145 3.45086 107.332 7.16839 106.153 10.1605C92.9149 8.16577 79.7675 8.16577 66.8016 10.1605C65.5322 7.16839 63.7187 3.45086 62.1773 0.730713C49.6647 2.90683 37.7867 6.71503 26.6341 11.8833C4.14757 45.7944 -1.92742 78.8898 1.06474 111.441C16.0255 122.593 30.4423 129.303 44.6777 133.746C48.2139 128.94 51.2967 123.772 54.0169 118.423C48.8486 116.518 43.9524 114.07 39.3281 111.26C40.5975 110.353 41.7762 109.355 42.955 108.449C71.3351 121.687 102.073 121.687 130.09 108.449C131.269 109.446 132.448 110.353 133.717 111.26C129.093 114.07 124.106 116.428 119.028 118.423C121.748 123.772 124.831 128.94 128.367 133.746C142.603 129.303 157.11 122.593 171.98 111.441C175.517 73.6309 165.905 40.8982 146.411 11.8833H146.23ZM57.8251 91.4932C49.302 91.4932 42.3203 83.6047 42.3203 73.9029C42.3203 64.201 49.1206 56.3126 57.8251 56.3126C66.5295 56.3126 73.4205 64.201 73.3298 73.9029C73.3298 83.5141 66.5295 91.4932 57.8251 91.4932ZM115.039 91.4932C106.516 91.4932 99.5339 83.6047 99.5339 73.9029C99.5339 64.201 106.334 56.3126 115.039 56.3126C123.743 56.3126 130.634 64.201 130.544 73.9029C130.544 83.5141 123.743 91.4932 115.039 91.4932Z" fill="white"/>
        </svg>


    )
}

export {DiscordSvgComponent};