import React from "react";

function CheckMarkSvgComponent(props: { className?: string, onClick: React.MouseEventHandler<SVGElement> }): React.JSX.Element {
    return (
        <svg onClick={(event: React.MouseEvent<SVGElement>) => props.onClick && props.onClick(event)} className={props.className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.2778 33H3.72222C2.77107 33 2 32.2289 2 31.2778V3.72223C2 2.77107 2.77107 2.00001 3.72222 2.00001H31.2778C32.2289 2.00001 33 2.77107 33 3.72223V31.2778C33 32.2289 32.2289 33 31.2778 33Z" stroke="#D0D0D0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export {CheckMarkSvgComponent};