import React, { useEffect } from "react";
import styles from './Confidentiality.module.scss';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useLocation } from "react-router-dom";
function Confidentiality(): React.JSX.Element {
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.scroll === false) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return (
        <>
        <Header />
        <main className={styles.main}>
            <div className={styles.conf}>
                <div className={styles.omni}>Политика конфиденциальности OMNI</div>
                <p>Мы серьезно относимся к вашей конфиденциальности и стремимся защищать  ваши личные данные. Эта политика конфиденциальности объясняет, как мы  собираем, используем, раскрываем, храним и защищаем вашу информацию,  когда вы используете нашего бота и сайт https://omnibot.su</p>
                <p>Мы собираем  информацию, которую мы получаем честный и законным способом, например, ваше имя в Discord, ID пользователя, сообщения, которые вы отправляете боту, а также информацию о том, как вы взаимодействуете с сайтом.</p>
                <p>Мы используем собранную информацию для:</p>
                <ul>
                    <li>Предоставления и улучшения функциональности бота.</li>
                    <li>Обработки ваших запросов и ответов.</li>
                    <li>Отслеживания  аномалий  и  потенциальных  угроз  безопасности.</li>
                    <li>Связи с вами (если это необходимо).</li>
                </ul>
                <p>Мы можем раскрыть вашу информацию:</p>
                <ul>
                    <li>В соответствии с законом или по запросу государственных органов Российской Федерации.</li>
                    <li>Если  мы  считаем,  что  раскрытие  необходимо  для  защиты  наших  прав, собственности  или  безопасности.</li>
                </ul>
                <p>Мы храним собранную информацию ровно столько времени, сколько необходимо для предоставления заявленной функциональности.</p>
                <p>Мы  принимаем  разумные  меры  для  защиты  собранной  информации  от несанкционированного  доступа, использования,  раскрытия,  изменения   или  уничтожения.</p>
                <p>У  вас  есть  право  на  доступ,  изменение  или  удаление  вашей   информации.  Вы  можете  связаться  с нами в Discord или по почте support@omnibot.su,  чтобы  воспользоваться   этими  правами.</p>
                <p>Наш сайт и другие наши ресурсы могут ссылаться на внешние сайты, которые  не управляются нами. Имейте в виду, что мы не контролируем  содержимое этих сайтов и не можем принять на себя ответственность за их  собственные политики конфиденциальности.</p>
                <p>Если  у  вас  есть  какие-либо  вопросы  по  этой  политике   конфиденциальности  или  по  обработке вашей  информации,  пожалуйста,   свяжитесь  с  нами  по  адресу  support@omnibot.su.</p>
                <p>Дальнейшее использование нашего проекта, веб-сайта и связанных с ними  ресурсов будет рассматриваться как согласие с нашей политикой  конфиденциальности.</p>
            </div>
        </main>
        <Footer />
        </>
    )
}

export {Confidentiality}