import ReactDOM from 'react-dom/client'
import { WelcomeApp } from './WelcomeApp'
import { GuildApp } from './GuildsApp';
import { ServerApp } from './ServerApp';
import { Confidentiality } from './Confidentiality';
import { Cookie } from './Cookie';
import { TermsOfUse } from './TermsOfUse';
import './normalize.css'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { CommandApp } from './CommandApp';
const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
      <Router>
        <Routes>
          <Route path="/" element={<WelcomeApp />} />
          <Route path="/guilds" element={ <GuildApp />} />
          <Route path="/guilds/:guildId" element={ <ServerApp />} />
          <Route path='/guilds/:guildId/:commandId' element={<CommandApp/>}/>
          <Route path="/confidentiality" element={ <Confidentiality />} />
          <Route path="/cookie" element={ <Cookie />} />
          <Route path="/terms-of-use" element={ <TermsOfUse />} />
        </Routes>
    </Router>
)