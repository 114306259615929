import React from "react";

function AddToServerTextSvgComponent(props: { className?: string }): React.JSX.Element {
    return (

<svg
    className={props.className}
    width="234.494"
    height="234.30901"
    viewBox="0 0 62.043204 61.994258"
    version="1.1"
    id="svg1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg">
  <defs
     id="defs1">
  </defs>
  <g
     id="layer1">
    <path
       id="path1"
       style={{
         opacity: 0.980583,
         fill: '#0056ff',
         fillOpacity: 0,
         fillRule: 'evenodd',
         strokeWidth: 1.1886,
         strokeLinecap: 'round',
         strokeLinejoin: 'round',
         strokeOpacity: 0,
       }}
       transform="rotate(-19.099927)"
       d="M 45.745941,39.502865 A 26.458332,26.458334 0 0 1 19.287609,65.961199 26.458332,26.458334 0 0 1 -7.170723,39.502865 26.458332,26.458334 0 0 1 19.287609,13.044531 26.458332,26.458334 0 0 1 45.745941,39.502865 Z" />
<text
       xmlSpace="preserve"
       style={{
         fontStyle: 'normal',
         fontVariant: 'normal',
         fontWeight: 600,
         fontStretch: 'normal',
         fontSize: '6.35px',
         fontFamily: "'Argentum Sans'",
         fontVariantLigatures: 'normal',
         fontVariantCaps: 'normal',
         fontVariantNumeric: 'normal',
         fontVariantEastAsian: 'normal',
         letterSpacing: '0.0211667px',
         opacity: 1,
         fill: '#ffffff',
         fillOpacity: 1,
         fillRule: 'evenodd',
         stroke: 'none',
         strokeWidth: 1.32292,
         strokeLinecap: 'round',
         strokeLinejoin: 'round',
         strokeOpacity: 0,
       }}
       id="text12"
       transform="rotate(-159.11211,31.02288,31.032289)"><textPath
         xlinkHref="#path1"
         id="textPath1"
         style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 600,
            fontStretch: 'normal',
            fontSize: '6.35px',
            fontFamily: "'Argentum Sans'",
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
            letterSpacing: '0.0211667px',
            fill: '#ffffff',
            fillOpacity: 1,
          }}
         >ДОБАВИТЬ НА СЕРВЕР  ДОБАВИТЬ НА СЕРВЕР</textPath></text>
  </g>
</svg>

    )
}
export {AddToServerTextSvgComponent};
