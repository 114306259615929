import React from "react";

function BoostySvgComponent(props: { className?: string }): React.JSX.Element {
    return (
        <svg className={props.className} width="51" height="63" viewBox="0 0 51 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.04365 37.5571L12.5244 0.890381H28.6313L25.3842 12.2574C25.3521 12.3223 25.3199 12.3873 25.2878 12.4522L16.736 42.4611H24.7091C21.3655 50.8727 18.7614 57.4655 16.8968 62.2397C2.17225 62.0773 -1.9429 51.4248 1.65785 38.8237M16.9611 62.2721L36.3794 34.0495H28.1491L35.3185 15.9598C47.5996 17.2589 53.3865 27.0345 49.9787 38.8561C46.3458 51.5547 31.6213 62.2721 17.2825 62.2721C17.1539 62.2721 17.0575 62.2721 16.9611 62.2721Z" fill="white"/>
        </svg>



    )
}

export {BoostySvgComponent};