import React, { useEffect, useState, useTransition } from 'react';
import styles from './ServerApp.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {SelectButton} from './SelectButton';
import { MainPanel } from './MainPanel';
import { GuildData } from '../types';
import { Header } from '../Header';
import { Footer } from '../Footer';
type Tab = "common" | "commands" | "music" | "economic" | "rating" | "audit";
function ServerApp():React.JSX.Element {
    const { guildId } = useParams();
    const [guildData, setGuildData] = useState<null | GuildData>(null);
    const [cookies] = useCookies(['user_token']);
    const [, startTransition] = useTransition();
    const [tab, setTab] = useState<Tab>(sessionStorage.getItem('tab') as Tab || 'common');
    const [activeLeft, setActiveLeft] = useState(Number(sessionStorage.getItem('left')) || 16);
    const [activeWidth, setActiveWidth] = useState(Number(sessionStorage.getItem('width')) || 124);
    const [activeColor, setActiveColor] = useState<'#D9D9D9' | '#a0a0a0'>('#D9D9D9');
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.scroll === false) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    useEffect(() => {
        (async() => {
          if (!cookies.user_token) {
            navigate('/', { state: { scroll: false } });
          }
    
          const haveAccess = await fetch(`https://omnibot.su/api/getServer?guildId=${guildId}`, {
            headers: {
              Authorization: cookies.user_token
            }
          });
          if (haveAccess.status !== 202) {
            navigate('/', { state: { scroll: false } });
          }
    
          const guild = await haveAccess.json();
          setGuildData(guild)
        })()
    }, [cookies.user_token, guildId, navigate])
  const selectTab = (evt: React.MouseEvent<HTMLButtonElement>, nextTab: Tab) => {
    startTransition(() => {
      let elem = evt.target as HTMLButtonElement;
      elem = elem.nodeName === 'SPAN' ? elem.parentElement as HTMLButtonElement : elem;
      setActiveWidth(elem.offsetWidth * 0.8);
      setActiveLeft(elem.offsetLeft + elem.offsetWidth * 0.103);
      setTab(nextTab);
      sessionStorage.setItem('tab', nextTab);
      sessionStorage.setItem('left', String(elem.offsetLeft + elem.offsetWidth * 0.103));
      sessionStorage.setItem('width', String(elem.offsetWidth * 0.8));
      setActiveColor('#a0a0a0');
    });
  }
  const mouseOver = (target: string) => {
    if (target === tab) {
      startTransition(() => setActiveColor('#a0a0a0'));
    }
  }
  return (
    <>
      <Header />
      <main className={styles.main}>
          <div className={styles.top}>
            <div className={styles.background}></div>
            <div className={styles.name}>{guildData?.name}</div>
          </div>
          <div className={styles.selectMenu}>
            <div className={styles.active} style={{left: activeLeft + 'px', width: activeWidth + 'px', backgroundColor: activeColor}}></div>
            <SelectButton isActive={tab === 'common'} onClick={(evt) => selectTab(evt, 'common')} onMouseOver={() => mouseOver('common')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>ОБЩЕЕ</SelectButton>
            <SelectButton isActive={tab === 'commands'} onClick={(evt) => selectTab(evt, 'commands')} onMouseOver={() => mouseOver('commands')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>КОМАНДЫ</SelectButton>
            <SelectButton isActive={tab === 'music'} onClick={(evt) => selectTab(evt, 'music')} onMouseOver={() => mouseOver('music')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>МУЗЫКА</SelectButton>
            <SelectButton isActive={tab === 'economic'} onClick={(evt) => selectTab(evt, 'economic')} onMouseOver={() => mouseOver('economic')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>ЭКОНОМИКА</SelectButton>
            <SelectButton isActive={tab === 'rating'} onClick={(evt) => selectTab(evt, 'rating')} onMouseOver={() => mouseOver('rating')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>РЕЙТИНГ</SelectButton>
            <SelectButton isActive={tab === 'audit'} onClick={(evt) => selectTab(evt, 'audit')} onMouseOver={() => mouseOver('audit')} onMouseOut={() => startTransition(() => setActiveColor('#D9D9D9'))}>АУДИТ</SelectButton>
          </div>
          <MainPanel type={tab} data={guildData} />
      </main>
      <Footer />
    </>
  );
}

export { ServerApp };