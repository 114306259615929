import React, { CSSProperties } from "react";

function CrossSvgComponent(props: { className?: string, styles?: CSSProperties }): React.JSX.Element {
    return (
        <svg className={props.className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={props.styles} d="M16 1L1 16M1.00003 1L16 16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>




    )
}

export {CrossSvgComponent};